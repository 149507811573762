import { createSlice } from '@reduxjs/toolkit'

// general imports
import {
  ApiRequestDataType,
  ApiRequestStatus,
  StoredErrorResponseType,
} from '../../../types/api/api.types'
import { RegistrationDataType } from '../../../types/auth.types'
import { loginUserThunk, resendOptCodeThunk } from '../thunks/auth.thunk'

export type LoginUserState = {
  // eslint-disable-next-line
  requestResponse: ApiRequestDataType<any>
  // eslint-disable-next-line
  resendOptCode: ApiRequestDataType<any>
  user: RegistrationDataType | null
}

export const initialState: LoginUserState = {
  requestResponse: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  resendOptCode: {
    status: ApiRequestStatus.IDLE,
    data: null,
  },
  user: {},
}

const loginUserSlice = createSlice({
  name: 'loginUserSlice',
  initialState: initialState,
  reducers: {
    resetLoginUserState: (state) => {
      ;(state.requestResponse.status = ApiRequestStatus.IDLE), (state.requestResponse.data = null)
    },
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserThunk.pending, (state) => {
        state.requestResponse.status = ApiRequestStatus.PENDING
        state.requestResponse.data = null
        state.requestResponse.error = {} as StoredErrorResponseType
      })
      .addCase(loginUserThunk.fulfilled, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.FULFILLED
        state.requestResponse.data = action.payload.description
        // if (state.user) {
        //   LocalStorageService.storeCurrentUser(state.user as UserEntityType)
        // } else {
        //   console.error('state.user is null')
        // }
        console.log('meine user2', action.payload)
      })
      .addCase(loginUserThunk.rejected, (state, action) => {
        state.requestResponse.status = ApiRequestStatus.REJECTED
        state.requestResponse.error = action.payload as StoredErrorResponseType
      })
      .addCase(resendOptCodeThunk.pending, (state) => {
        state.resendOptCode.status = ApiRequestStatus.PENDING
        state.resendOptCode.data = null
        state.resendOptCode.error = {} as StoredErrorResponseType
      })
      .addCase(resendOptCodeThunk.fulfilled, (state, action) => {
        state.resendOptCode.status = ApiRequestStatus.FULFILLED
        state.resendOptCode.data = action.payload.description
      })
      .addCase(resendOptCodeThunk.rejected, (state, action) => {
        state.resendOptCode.status = ApiRequestStatus.REJECTED
        state.resendOptCode.error = action.payload as StoredErrorResponseType
      })
  },
})

export const { resetLoginUserState, setUser } = loginUserSlice.actions
export default loginUserSlice.reducer
