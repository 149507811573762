import { IconPropsType } from '../../types'
import { IconRepository } from '../icons/icon.repository'

export enum DashboardType {
  ADMIN = 0,
  INITIATOR = 1,
  VALIDATOR = 2,
  SUPER_ADMIN = 3,
}

export type DataLinksType = {
  link: string
  label: string
  icon: ({ width, height, color }: IconPropsType) => JSX.Element
  ref?: DashboardType[]
}

export const upperSidebarLinks: DataLinksType[] = [
  {
    link: '/dashboard',
    label: 'Overview',
    icon: IconRepository.OverviewIcon,
    ref: [DashboardType.ADMIN, DashboardType.INITIATOR, DashboardType.VALIDATOR],
  },
  {
    link: '/dashboard/transactions',
    label: 'Transactions',
    icon: IconRepository.AdminTransactionIcon,
    ref: [DashboardType.ADMIN, DashboardType.INITIATOR, DashboardType.VALIDATOR],
  },
  // {
  //   link: '/dashboard/history',
  //   label: 'History',
  //   icon: IconRepository.AdminHistoryIcon,
  //   ref: [DashboardType.ADMIN, DashboardType.INITIATOR, DashboardType.VALIDATOR],
  // },
]

export const UpperSidebarAdminLinks: DataLinksType[] = [
  ...upperSidebarLinks,
  {
    link: '/dashboard/users',
    label: 'Users',
    icon: IconRepository.UsersIcon,
    ref: [DashboardType.ADMIN],
  },
  {
    link: '/dashboard/beneficiaries',
    label: 'Beneficiaries',
    icon: IconRepository.WalletIcon,
    ref: [DashboardType.ADMIN],
  },
  // {
  //   link: '/dashboard/stripe',
  //   label: 'Stripe',
  //   icon: IconRepository.ExpensesIcon,
  //   ref: [DashboardType.ADMIN],
  // },
  // {
  //   link: '/dashboard/balances',
  //   label: 'Balances',
  //   icon: IconRepository.WalletIcon,
  //   ref: [DashboardType.ADMIN],
  // },
  // {
  //   link: '/dashboard/target',
  //   label: 'Target',
  //   icon: IconRepository.TargetIcon,
  //   ref: [DashboardType.ADMIN],
  // },
]

export const UpperSidebarValidatorLinks: DataLinksType[] = [...upperSidebarLinks]

export const UpperSidebarInitiatorLinks: DataLinksType[] = [
  ...upperSidebarLinks,
  {
    link: '/dashboard/beneficiaries',
    label: 'Beneficiaries',
    icon: IconRepository.WalletIcon,
    ref: [DashboardType.INITIATOR],
  },
]

export const LowerSidebarLinks = [
  {
    link: '/dashboard/settings',
    label: 'Settings',
    icon: IconRepository.SettingsIcon,
    ref: [
      DashboardType.ADMIN,
      DashboardType.INITIATOR,
      DashboardType.SUPER_ADMIN,
      DashboardType.VALIDATOR,
    ],
  },
  // {
  //   link: '/dashboard/profile',
  //   label: 'Profile',
  //   icon: IconRepository.ProfileIcon,
  //   ref: [
  //     DashboardType.ADMIN,
  //     DashboardType.INITIATOR,
  //     DashboardType.SUPER_ADMIN,
  //     DashboardType.VALIDATOR,
  //   ],
  // },
  {
    link: 'login',
    label: 'Logout',
    icon: IconRepository.LogoutIcon,
    ref: [
      DashboardType.ADMIN,
      DashboardType.INITIATOR,
      DashboardType.SUPER_ADMIN,
      DashboardType.VALIDATOR,
    ],
  },
]
