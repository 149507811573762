import { publicApiRequest } from '../../lib/hooks/axios-instance'
import { addTokenToHeaders } from '../../lib/utils/addTokenReq'
import { APP_CONSTANTS } from '../../repository/constants/constants'

// import { TokenPrimeService } from '../token/token.service'

// const tokenServicePrime = new TokenPrimeService()
// const { accessToken } = (await tokenServicePrime.getTokenPairToCookie()).description || {}
/** )
 * Authentication service
 */
// Admin
// const token =
//   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2ODVkMWU1Yy04NDZmLTQ2ZmItODVlNS1jOWYyOTk0MWM4NzYiLCJvcmdhbmlzYXRpb25faWQiOiI2ODVkMWU1Yy04NDZmLTQ2ZmItODVlNS1jOWYyOTk0MWM4NzYiLCJyb2xlcyI6WyJBRE1JTiIsIlVTRVIiLCJJTklUSUFUT1IiLCJWQUxJREFUT1IiXSwiaXNzIjoiaHR0cHM6Ly9hdXRoL2F1dGgvb3RwLWxvZ2luIiwiZXhwIjoxNjkzMTA4MzA2LCJpYXQiOjE2OTI5MzU1MDZ9.OGIKEaoBbOMRaq73OJaAOXZKCLCfY5jagKuHhHrC1jA'

// const superAdToken =
//   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4YjUxZTYxZS1lOTE3LTQ0NWEtOGM2My1jNzU5NGJiYzM3ODIiLCJvcmdhbmlzYXRpb25faWQiOiIzZjk5N2EyNS0yY2FhLTRmYTMtOTBjNS05NDQxMTAzMjRlNmUiLCJyb2xlcyI6WyJVU0VSIiwiU1VQRVJfQURNSU4iXSwiaXNzIjoiaHR0cHM6Ly9hdXRoL2F1dGgvb3RwLWxvZ2luIiwiZXhwIjoxNjkzMTEwMTcyLCJpYXQiOjE2OTI5MzczNzJ9.mufvMAGVC9SAWKFjAp395uckjtBaYa_wq2-K_EDJUtg'

export interface addAutomaticTransactionTypes {
  beneficiaryId: string
  amount: string
  reason: string
  token: string
}

export interface addAutomaticTransactionTypesV2 extends addAutomaticTransactionTypes {
  receiverMessage: string
  transactionFile: File
}
export interface addManualTransactionTypes {
  name: string
  email: string
  number: string
  accountType: string
  amount: string
  reason: string
  token: string
}

export interface addManualTransactionTypesV2 extends addManualTransactionTypes {
  receiverMessage: string
  transactionFile: File
}

const config = {
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(APP_CONSTANTS.STORAGE_KEY.ACCESS_TOKEN)}`,
  },
}

export class TransactionService {
  /**
   * Get all Transactions
   * @returns Promise<any>
   */
  public async getAllTransactions(token: string) {
    return await (await publicApiRequest()).get('/transaction', addTokenToHeaders(token))
  }
  /**
   * Get a specific Transaction
   * @returns Promise<any>
   */
  public async getTransaction(token: string) {
    return await (await publicApiRequest()).get('/transaction', addTokenToHeaders(token))
  }

  /**
   * Get all beneficiaries
   * @returns Promise<any>
   */
  public async getAllBeneficiaries(token: string) {
    return await (
      await publicApiRequest()
    ).get('/transaction/beneficiaries', addTokenToHeaders(token))
  }

  /**
   *  Add automatic transaction
   * @returns Promise<any>
   */
  public async addAutomaticTransaction(data: addAutomaticTransactionTypes) {
    return await (
      await publicApiRequest()
    ).post(
      `/transaction/${data.beneficiaryId}`,
      { amount: data.amount, reason: data.reason },
      addTokenToHeaders(data.token),
    )
  }

  /**
   *  Add automatic transaction version 2
   * @returns Promise<any>
   */
  public async addAutomaticTransactionV2(data: addAutomaticTransactionTypesV2) {
    return await (
      await publicApiRequest()
    ).post(
      `/transaction/v2/${data.beneficiaryId}`,
      {
        amount: data.amount,
        reason: data.reason ?? '',
        receiverMessage: data.receiverMessage ?? '',
        transactionFile: data.transactionFile,
      },
      addTokenToHeaders(data.token, 'multipart/form-data'),
    )
  }

  /**
   * Add manual transaction
   * @returns Promise<any>
   */
  public async addManualTransaction(data: addManualTransactionTypes) {
    return await (
      await publicApiRequest()
    ).post(
      '/transaction',
      {
        amount: data.amount,
        reason: data.reason,
        name: data.name,
        email: data.email,
        number: data.number,
        accountType: data.accountType,
      },
      addTokenToHeaders(data.token),
    )
  }

  /**
   * Add manual transaction version 2
   * @returns Promise<any>
   */
  public async addManualTransactionV2(data: addManualTransactionTypesV2) {
    return await (
      await publicApiRequest()
    ).post(
      '/transaction/v2',
      {
        amount: data.amount,
        reason: data.reason ?? '',
        name: data.name,
        email: data.email ?? '',
        number: data.number,
        accountType: data.accountType,
        receiverMessage: data.receiverMessage,
        transactionFile: data.transactionFile,
      },
      addTokenToHeaders(data.token, 'multipart/form-data'),
    )
  }

  /**
   * Post a single payment request
   * @returns Promise<any>
   */
  public async singlePaymentTransaction() {
    return await (await publicApiRequest()).post('/transaction/request-payment', config)
  }

  /**
   * Get a Transaction history
   * @returns Promise<any>
   */
  public async getTransactionHistory(token: string) {
    return await (await publicApiRequest()).get('/transaction/history', addTokenToHeaders(token))
  }

  public async getUpcomingBill(token: string) {
    return await (
      await publicApiRequest()
    ).get('/transaction/upcoming-bill', addTokenToHeaders(token))
  }
  /**
   * validate transaction
   * @returns Promise<any>
   */
  public async validateTransaction(data: { transactionId: string; token: string; type: string }) {
    const endpoint = `/transaction/approve${data.type === 'Bulk' ? '-bulk' : ''}/${
      data.transactionId
    }`
    return await (
      await publicApiRequest()
    ).patch(endpoint, data, addTokenToHeaders(data.token ?? ''))
  }

  /**
   * cancel transaction
   * @returns Promise<any>
   */
  public async cancelTransaction(data: { transactionId: string; token: string; type: string }) {
    const endpoint = `/transaction/cancel${data.type === 'Bulk' ? '-bulk' : ''}/${
      data.transactionId
    }`
    return await (
      await publicApiRequest()
    ).patch(endpoint, data, addTokenToHeaders(data.token ?? ''))
  }
  /**
   * delete transaction
   * @returns Promise<any>
   */
  public async deleteTransaction(data: { transactionId: string; token: string }) {
    return await (
      await publicApiRequest()
    ).delete(`/transaction/delete/${data.transactionId}`, addTokenToHeaders(data.token ?? ''))
  }

  /**
   * Download transaction
   * @returns Promise<any>
   */
  public async downloadTransaction(data: { transactionId: string; token: string }) {
    return await (
      await publicApiRequest(undefined, 'blob')
    ).get(`/transaction/generate-pdf/${data.transactionId}`, addTokenToHeaders(data.token ?? ''))
  }

  public async downloadBulkTransaction(data: { transactionId: string; token: string }) {
    return await (
      await publicApiRequest(undefined, 'blob')
    ).get(
      `/transaction/generate-bulk-pdf/${data.transactionId}`,
      addTokenToHeaders(data.token ?? ''),
    )
  }
  /**
   * export CSV admin
   * @returns Promise<any>
   */
  public async exportAdminCSV(token: string) {
    return await (
      await publicApiRequest()
    ).get('/transaction/export-csv', addTokenToHeaders(token ?? ''))
  }
  /**
   * export CSV super-admin
   * @returns Promise<any>
   */
  public async exportSuperAdminCSV(token: string) {
    return await (
      await publicApiRequest()
    ).get('/profile/export-org-csv', addTokenToHeaders(token ?? ''))
  }
  //   public async approveBulkTransaction(bulk_transaction_id: string) {
  //     return await publicApiRequest().patch(`/transaction/approve-bulk/${bulk_transaction_id}`)
  //   }
  //   public async approveTransaction(transaction_id: string) {
  //     return await publicApiRequest().patch(`/transaction/approve/${transaction_id}`)
  //   }
  //   public async getBulkTransaction() {
  //     return await publicApiRequest().get('/transaction/bulk-transactions')
  //   }
  //   public async bulkTransfer(tag: string) {
  //     return await publicApiRequest().post(`/transaction/bulk_transfer/${tag}`)
  //   }
  //   public async cancelTransaction(transaction_id: string) {
  //     return await publicApiRequest().patch(`/transaction/cancel/${transaction_id}`)
  //   }
  //   public async confirmTransaction() {
  //     return await publicApiRequest().put(`/transaction/confirm`)
  //   }
}
